import { getCommonParamsItemLabel } from '@/utils/common.js'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'value',
      label: 'profile.code'
    },
    {
      prop: 'key',
      label: 'general.name'
    },
    {
      prop: 'status',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
      // slotName: 'formateTime'
    },
    {
      label: 'common.app-operation',
      width: '400',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
