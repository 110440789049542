<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="title"
      :showBackBtn="true"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isSwitch && isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleDisableClick(scope.row)"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="isSwitch && !isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row)"
          >{{ $t('general.enable') }}</el-button
        >
      </template>
    </page-content>
    <el-dialog
      v-model="dialogFormVisible"
      title="Data Dictionary"
      width="500px"
    >
      <el-form ref="formRef" :model="form" :rules="rulesRef">
        <el-form-item
          :label="$t('profile.code')"
          prop="value"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.value"
            :disabled="pageType === 'edit'"
            autocomplete="off"
            maxlength="255"
          />
        </el-form-item>
        <el-form-item
          :label="$t('general.name')"
          prop="key"
          :label-width="formLabelWidth"
          maxlength="100"
        >
          <el-input v-model="form.key" maxlength="100" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSubmit()">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, computed, nextTick, getCurrentInstance } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'

import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash'
import { usePermission } from '@/hooks/use-permission'
import i18n from '@/i18n'

const routes = useRoute()
const store = useStore()

const id = routes.params.id
const pageInitialParams = {
  typeId: id
}
const { t } = i18n.global
const title =
  t('general.router-data-dictionary-detail') + ' - ' + routes.params.name

// 表格基础配置项
const tableOptions = {
  moduleName: 'system', // 所属的模块
  pageName: 'dictDetail', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  isShowViewBtn: false,
  pageInitialQuery: pageInitialParams,
  pageAction: 'system/getDataDictionaryDetailList', // 获取表格的Action
  deleteRecordAction: 'system/deleteDataDictionaryDetailById', // 删除record的Action
  pageCountGetter: 'system/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'system/pageListData' // 获取表格列表数据getters,
}

const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)

const isNormalStatus = (status) => {
  return status === '00'
}

const pageSearchRef = ref('')

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')

const dialogFormVisible = ref(false)
const formLabelWidth = '140px'
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const form = reactive({
  key: '',
  value: '',
  typeId: id
})

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData({
    ...pageInitialParams,
    ...queryInfo
  })
}

const handleResetClick = () => {
  pageContentRef.value.getPageData(pageInitialParams)
}
// const router = useRouter()
// const handleViewData = (id) => {
//   dialogFormVisible.value = true
// }
const pageType = ref('new')

const rulesRef = ref({
  key: {
    required: true,
    message: t('general.required'),
    trigger: 'blur'
  },
  value: {
    required: true,
    message: t('general.required'),
    trigger: 'blur'
  }
})

const rowId = ref('')
const formRef = ref('')

const handleEditData = async (id) => {
  const data = await store.dispatch('system/getDataDictionaryDetailById', {
    id
  })
  pageType.value = 'edit'
  form.key = data.key
  form.value = data.value
  rowId.value = id

  dialogFormVisible.value = true
  rulesRef.value.value.required = pageType.value !== 'edit'
  nextTick(() => {
    formRef.value.clearValidate()
  })
}

const handleNewData = () => {
  pageType.value = 'new'
  form.key = ''
  form.value = ''

  dialogFormVisible.value = true
  rulesRef.value.value.required = pageType.value !== 'edit'
  nextTick(() => {
    formRef.value.clearValidate()
  })
}

const handleSubmit = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      let url = 'handleAddDataDictionaryDetail'
      const data = cloneDeep(form)
      if (pageType.value === 'edit') {
        url = 'handleEditDataDictionaryDetail'
        data.id = rowId.value
      }
      handleMessageCommit(`system/${url}`, data).then(() => {
        dialogFormVisible.value = false
        pageInfoChangeAction()
      })
    }
  })
}

const handleDisableClick = async (row) => {
  handleMessageCommit('system/handleDisableDataDictionaryDetail', row.id).then(
    () => {
      pageContentRef.value.getPageData(pageInitialParams)
    }
  )
}

const handleEnableClick = async (row) => {
  handleMessageCommit('system/handleEnableDataDictionaryDetail', row.id).then(
    () => {
      pageContentRef.value.getPageData(pageInitialParams)
    }
  )
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData({
    ...pageInitialParams,
    ...formData
  })
}
</script>
